import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import { useEffect, useState } from "react";
import { API_BASE_URL } from "config";

const Resource = ({ formData, setFormData, handleInputChange }) => {
  return (
    <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
      {/* Header */}
      <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
        Product Category
      </h4>

      {/* content */}
      {/* inputs */}
      <div className="mt-6 grid grid-cols-1 gap-3">
        <div className="col-span-2 md:col-span-1">
          <InputField
            extra="mb-4"
            label="Name"
            placeholder="eg. Electronic"
            id="name"
            type="text"
            value={formData.name}
            onChange={handleInputChange}
          />
          <TextField
            extra="mb-4"
            label="Description"
            placeholder="eg. Description of electronic category ..."
            id="description"
            // type="text"
            rows="7"
            value={formData.description}
            onChange={handleInputChange}
          />

        </div>

      </div>
    </div >
  );
};

export default Resource;
