import React, { useEffect, useState } from "react";
import { useFetchData } from 'services/queries';
import Card from 'components/card';
import Box from '@mui/material/Box';
import {
  DataGridPremium,
} from '@mui/x-data-grid-premium';
import useAuth from "hooks/useAuth";
import { Link } from "react-router-dom";
import { BsPencilSquare, BsTrash } from "react-icons/bs";
import { GridActionsCellItem } from '@mui/x-data-grid';
import useRoleBasedColumns from 'hooks/useRoleBasedColumns';
import { BsFillEyeFill } from "react-icons/bs";
import DeleteConfirmDialog from 'components/dialog/DeleteConfirmDialog';
import ToolbarCrud from 'components/toolbar/ToolbarCrud';

const ListObjects = () => {
  const resource = 'product';
  const resource_name = 'Product';
  const { data: data, error: errorData, isLoading: isLoadingData } = useFetchData(resource, true);
  const { data: skuData, error: ErrorSkuData, isLoading: isLoadingSkuData } = useFetchData('product/client-sku', true);
  const { auth, authLoading } = useAuth();
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);

  const columnsData = [
    {
      field: 'name',
      headerName: 'PRODUCT NAME',
      mindWidth: 400,
      flex: 1,
      editable: false,
      groupable: false,
      // render with image
      renderCell: (params) => {
        return (
          <div className="flex items-center">
            {params.row.picture ? (
              <img src={params.row.picture} alt={params.row.name} className="w-10 max-h-10 object-contain rounded-full" />
            ) : (
              <div className="w-10 h-10 rounded-full bg-gray-300"></div>
            )}
            <p className="ml-2">{params.row.name}</p>
          </div>
        )
      }

    },
    {
      field: 'sku',
      headerName: 'SKU',
      minwidth: 100,
      editable: false,
      groupable: false,
      type: 'string',
    },
    {
      field: 'client_skus',
      headerName: 'Client SKU',
      minwidth: 100,
      editable: false,
      groupable: false,
      type: 'string',
    },
    {
      field: 'actions_admin',
      headerName: 'Actions',
      type: 'actions',
      editable: false,
      groupable: false,
      aggregable: false,
      visibleTo: ['Administrator', 'Staff'],
      getActions: (params) => {
        const { id } = params.row;
        return [
          <GridActionsCellItem
            icon={<BsPencilSquare />}
            color="primary"
            label="Edit"
            component={Link}
            to={`/admin/${resource}/edit/${id}`}
          />,
          <GridActionsCellItem
            icon={<BsFillEyeFill />}
            color="primary"
            label="View"
            component={Link}
            to={`/admin/${resource}/detail/${id}`}
          />,
          <GridActionsCellItem
            color="primary"
            icon={<BsTrash />}
            label="Delete"
            onClick={() => {
              setSelectedItem(params.row.id); // Set the selected item
              setOpenDeleteDialog(true); // Open the dialog
            }}
          />,
        ];
      }
    },
    {
      field: 'actions_client',
      headerName: 'Actions',
      type: 'actions',
      editable: false,
      groupable: false,
      aggregable: false,
      visibleTo: ["Client Administrator", "Client Staff"],
      getActions: (params) => {
        const { id } = params.row;
        return [
          <GridActionsCellItem
            icon={<BsFillEyeFill />}
            color="primary"
            label="View"
            component={Link}
            to={`/admin/${resource}/detail/${id}`}
          />
        ];
      }
    },
  ];
  const columns = useRoleBasedColumns(columnsData);

  if (isLoadingData | authLoading | isLoadingSkuData) return <div>Loading...</div>;
  if (errorData) return <div>Error! {errorData.message}</div>;
  if (ErrorSkuData) return <div>Error! {ErrorSkuData.message}</div>;

  const combinedData = data.map(product => {
    const skus = skuData.filter(sku => sku.product === product.id).map(sku => sku.sku);
    return { ...product, client_skus: skus };
  });



  const toolbar = () => {
    if (auth?.groups?.find(role => ["Administrator", "Staff"]?.includes(role))) {
      return (
        <ToolbarCrud
          resource={resource}
          name={resource_name}
        />
      )
    }
    else {
      return null;
    }
  };

  return (
    <Card extra={'w-full h-full bg-white mt-3 p-3'}>
      <Box>
        <DataGridPremium
          rows={combinedData}
          columns={columns.map(column => ({
            ...column,
            editable: false
          }))}
          pagination
          pageSizeOptions={[10, 25, 50, 100]}
          initialState={{
            density: 'comfortable',
            pinnedColumns: { left: ['actions'] },
            pagination: { paginationModel: { pageSize: 10 } },
            filter: {
              filterModel: {
                items: [],
                quickFilterExcludeHiddenColumns: false,
              },
            },
            columns: {
              columnVisibilityModel: {
                client_skus: false,
              },
            }
          }}
          slots={{ toolbar }}
        />
      </Box>
      <DeleteConfirmDialog
        openDeleteDialog={openDeleteDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
        selectedItem={selectedItem}
        resource={resource}
      />
    </Card>

  )
}

export default ListObjects