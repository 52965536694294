import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import { useEffect, useState } from "react";
import { API_BASE_URL } from "config";
import { useFetchData } from 'services/queries';

const Product = ({ formData, setFormData, handleInputChange }) => {
  const { data: categories, error: errorCategories, isLoading: isLoadingCategories } = useFetchData('product/category');

  if (isLoadingCategories) return <div>Loading...</div>
  if (errorCategories) return <div>{errorCategories.message}</div>

  return (
    <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
      {/* Header */}

      <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
        Product Info
      </h4>

      {/* content */}
      {/* inputs */}
      <div className="mt-6 grid grid-cols-2 gap-3">
        <div className="col-span-2 md:col-span-1">
          <InputField
            extra="mb-4"
            label="Product Name"
            placeholder="eg. Elegant Chair"
            id="name"
            type="text"
            value={formData.name}
            onChange={handleInputChange}
          />
          <InputField
            extra="mb-4"
            label="SKU"
            placeholder="eg. 12783"
            id="sku"
            type="text"
            value={formData.sku}
            onChange={handleInputChange}
          />

          <div className="w-full md:col-span-1">
            <div>
              <label
                htmlFor="currency"
                className="ml-3 mb-2 text-sm font-bold text-navy-700 dark:text-white"
              >
                Category
              </label>
              <div className="w-full rounded-xl border border-gray-200 py-3 px-2.5 text-sm text-gray-900 outline-none dark:!border-white/10 dark:!bg-navy-800">
                <select
                  id="category_id"
                  name="category_id"
                  className="w-full dark:!bg-navy-800"
                  value={formData.category_id}  // Use formData.category_id instead of formData.category.id
                  onChange={handleInputChange}  // Use handleInputChange to update the category_id field
                >
                  <option value="">Select a category</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        {/* right side */}
        <div className="col-span-2 md:col-span-1">
          {/* text area */}
          <TextField
            label="Description"
            placeholder="Short description about the product!"
            id="short_description"
            name="short_description"
            cols="30"
            rows="11"
            value={formData.short_description}
            onChange={handleInputChange}
          />

        </div>
        <div className="col-span-2">
          {/* text area */}
          <TextField
            label="Additional Information"
            placeholder="Additional information about the product"
            id="additional_information"
            name="additional_information"
            rows="7"
            value={formData.additional_information}
            onChange={handleInputChange}
          />
        </div>
      </div>
    </div >
  );
};

export default Product;
