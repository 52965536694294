import { useState, useEffect } from "react";
import React from 'react';
import { useFetchData, useFetchDataId } from "services/queries";
import Box from '@mui/material/Box';
import {
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import EditableDataGrid from "components/EditableDataGrid/EditableDataGrid";
import { GRID_AGGREGATION_ROOT_FOOTER_ROW_ID } from '@mui/x-data-grid-premium';
import useAuth from "hooks/useAuth";
import { useParams } from "react-router-dom";


const Items = ({ formData, setFormData, handleInputChange }) => {
  const { id } = useParams();
  const resourcePath = 'order/product-item-sales-order';
  const relatedResources = `order/product-item-sales-order/?sales_order=${formData.id}`;
  const [tableData, setTableData] = useState([]);
  const { data: products, isLoading: isLoadingProducts, error: errorProducts } = useFetchData('product', true);
  const apiRef = useGridApiRef();
  const { auth, authLoading } = useAuth();
  const [orderStatus, setOrderStatus] = useState(null);

  const { data: statusSalesOrder, isLoading: isLoadingStatusSalesOrder, error: errorStatusSalesOrder } = useFetchData('order/status-sales-order', true);
  const { data: order, error: errorOrder, isLoading: isLoadingOrder, refetch: refetchOrder } = useFetchDataId('order/simple-sales-order', id, true);
  const { data: skuData, error: errorSkuData, isLoading: isLoadingSkuData } = useFetchData(`product/client-sku/?client=${formData.buyer_id}`, true);


  useEffect(() => {
    if (statusSalesOrder && order) {
      setOrderStatus(() => statusSalesOrder.find(status => status.id === order?.status_id)?.status);
    }

  }, [statusSalesOrder, order]);

  const handleProductChange = (event, newValue, rowId) => {
    if (!newValue) {
      return;
    }
    const updatedRows = tableData.map(row => {
      if (row.id === rowId) {
        return { ...row, product: newValue, product_id: newValue.id, sku: newValue.sku };
      }
      return row;
    });
    setTableData(updatedRows);
    apiRef.current.setEditCellValue({ id: rowId, field: 'product', value: newValue });
    apiRef.current.setEditCellValue({ id: rowId, field: 'product_id', value: newValue.id });
    apiRef.current.setEditCellValue({ id: rowId, field: 'sku', value: newValue.sku });
  };
  const columnsDataItems = [
    // product picture
    {
      field: 'picture',
      headerName: '',
      editable: false,
      groupable: false,
      aggregable: false,
      maxWidth: 50,
      disableColumnMenu: true,
      hideSortIcons: true,
      renderCell: (params) => {
        if (params.id === GRID_AGGREGATION_ROOT_FOOTER_ROW_ID) {
          return [];
        }
        return (
          // box with small padding
          <Box style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', justify: 'center' }}>
            {
              params.row.product && params.row.product.picture ? (
                <img src={params.row.product.picture} className="w-10 h-10 object-contain rounded-full drop-shadow-md" alt='' />
              ) : (
                <div className="w-10 h-10 rounded-full bg-gray-100"></div>
              )
            }
          </Box>
        )
      }
    },
    {
      field: 'product_id',
      headerName: 'PRODUCT',
      editable: true,
      groupable: false,
      type: 'singleSelect',
      minWidth: 250,
      flex: 1,
      valueGetter: (value, row, column, apiRef) => {
        return row.product?.id;
      },
      valueOptions: products?.map((product) => ({
        value: product.id,
        label: product.name,
      })),
      renderEditCell: (params) => {
        return (
          <Autocomplete
            value={params.row.product || null}
            onChange={(event, newValue) => handleProductChange(event, newValue, params.id)}
            options={products}
            getOptionLabel={(option) => {
              const client_sku = skuData?.find(sku => sku.product === option.id)?.sku;
              return client_sku
                ? `${option.sku} - ${option.name} (Client SKU: ${client_sku})`
                : `${option.name} (SKU: ${option.sku})`;
            }}
            renderOption={(props, option) => {
              const client_sku = skuData?.find(sku => sku.product === option.id)?.sku;
              return (
                <li {...props}>
                  <div>
                    {option.name}
                    <br />
                    <span style={{ fontStyle: 'italic', fontSize: '0.8em' }}>
                      SKU: {option.sku}
                      {client_sku && ` | Client SKU: ${client_sku}`}
                    </span>
                  </div>
                </li>
              );
            }}
            renderInput={(params) => <TextField {...params} label="Select Product" />}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            fullWidth
          />
        )
      },
      valueSetter: (value, row) => {
        const selectedProduct = products.find(product => product.id === value);
        if (!selectedProduct) {
          return row;
        }
        const updatedRow = {
          ...row,
          product_id: selectedProduct.id,
          product: selectedProduct,
          quantity: row.quantity
        };
        return updatedRow;
      },
    },
    {
      field: 'sku',
      headerName: 'SKU',
      editable: false,
      groupable: false,
      valueGetter: (value, row, column, apiRef) => {
        return row.product?.sku;
      },
    },
    // client sku
    {
      field: 'client_sku',
      headerName: 'Client SKU',
      editable: false,
      groupable: false,
      type: 'string',
      valueGetter: (value, row, column, apiRef) => {
        return skuData?.find(sku => sku.product === row.product_id)?.sku;
      },
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      editable: true,
      type: 'number',
    },
    {
      field: 'total_cbm',
      headerName: 'CBM',
      editable: false,
      groupable: false,
      type: 'number',
      valueGetter: (value, row, column, apiRef) => {
        const total_cbm = row?.packing_list_item?.cmb_per_total || 0;
        return parseFloat(total_cbm);
      },
    },
    {
      field: 'total_gross_weight',
      headerName: 'Gross Weight',
      editable: false,
      groupable: false,
      type: 'number',
      valueGetter: (value, row, column, apiRef) => {
        const total_gross_weight = row?.packing_list_item?.total_gross_weight || 0;
        return parseFloat(total_gross_weight);
      },
    },
    {
      field: 'total_net_weight',
      headerName: 'Net Weight',
      editable: false,
      groupable: false,
      type: 'number',
      valueGetter: (value, row, column, apiRef) => {
        const total_net_weight = row?.packing_list_item?.total_net_weight || 0;
        return parseFloat(total_net_weight);
      },
    },
    // total containers
    {
      field: 'total_containers',
      headerName: 'Master Carton',
      editable: false,
      groupable: false,
      type: 'number',
      valueGetter: (value, row, column, apiRef) => {
        const total_containers = row?.packing_list_item?.total_ctns || 0;
        return parseFloat(total_containers);
      },
    },
  ];

  const newRowTemplate = {
    product_id: null,
    sku: '',
    quantity: 0,
    sales_order: formData.id,
    isNew: true
  }

  const columnGroupingModel = [
    {
      groupId: 'Total Packing List',
      description: '',
      children: [{ field: 'total_cbm' }, { field: 'total_gross_weight' }, { field: 'total_net_weight' }, { field: 'total_containers' }],
    },
    {
      groupId: 'Order Product Items',
      children: [{ field: 'product_id' }, { field: 'sku' }, { field: 'quantity' }],
    },
    {
      groupId: 'Actions',
      children: [{ field: 'actions' }, { field: 'picture' }],
    }
  ]

  if (isLoadingProducts | authLoading | isLoadingStatusSalesOrder | isLoadingOrder | isLoadingSkuData) return <div>Loading...</div>;
  if (errorProducts | errorStatusSalesOrder | errorOrder | errorSkuData) return <div>Error...</div>;

  let actions = {
    create: true,
    edit: true,
    delete: true,
  };



  if (auth?.groups?.find(role => ["Client Administrator", "Client Staff"].includes(role)) && orderStatus !== "Open" && orderStatus) {
    actions = {
      create: false,
      edit: false,
      delete: false,
    };
  }

  return (
    <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
      <h3 className="pt-[5px] text-2xl font-bold text-navy-700 dark:text-white">Order Items</h3>
      <Box>
        <EditableDataGrid
          columns={columnsDataItems}
          resourcePath={resourcePath}
          tableData={tableData}
          setTableData={setTableData}

          actions={actions}

          relatedResources={relatedResources}
          newRowTemplate={newRowTemplate}
          apiRef={apiRef}

          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
            aggregation: {
              model: {
                quantity: 'sum',
                total_cbm: 'sum',
                total_gross_weight: 'sum',
                total_net_weight: 'sum',
                total_containers: 'sum',
              },
            },
            pinnedColumns: { left: ['actions', 'picture'] },
            filter: {
              filterModel: {
                items: [],
                quickFilterExcludeHiddenColumns: false,
              },
            },
          }}
          autosizeOptions={{
            columns: ['product_id', 'sku', 'total_cbm', 'total_gross_weight', 'total_net_weight', 'total_containers'],
            includeOutliers: true,
            includeHeaders: true,
          }}
          columGroupingModel={columnGroupingModel}
          autosizeOnMount={true}
        />
      </Box>
    </div>
  );
};

export default Items;