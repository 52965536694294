import React from 'react'
import { useFetchData } from 'services/queries';
import {
    DataGridPremium,
} from '@mui/x-data-grid-premium';
import Grid from '@mui/material/Grid';
import {
    blueberryTwilightPalette,
    cheerfulFiestaPalette,
} from '@mui/x-charts/colorPalettes';
import { Typography } from '@mui/material';
import { useTheme, useMediaQuery } from "@mui/material";
import PieChart from 'components/pieChart/PieChart';
import ClientIncomingTooltip from './ClientIncomingTooltip';

const ClientIncoming = (props) => {
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const { startDate, endDate } = props;
    let params_url = ''
    if (startDate || endDate) {
        params_url = `?issue_date__gte=${startDate}&issue_date__lte=${endDate}`
    }
    const { data: dataInvoiceValueByClient, error: errorDataInvoiceValueByClient, isLoading: isLoadingDataInvoiceValueByClient } = useFetchData(`order/sales-order/total-invoice-value-by-client/${params_url}`, true);

    if (isLoadingDataInvoiceValueByClient) {
        return <div>Loading...</div>
    }
    if (errorDataInvoiceValueByClient) {
        return <div>Error</div>
    }
    console.log(dataInvoiceValueByClient)

    const rows = Object.entries(dataInvoiceValueByClient).map(([client, total_amount_invoice], index) => {
        return {
            id: index,
            client: client,
            total_amount_invoice: total_amount_invoice
        }
    });

    const totalInvoiceValue = Object.values(dataInvoiceValueByClient).reduce((acc, curr) => acc + curr, 0);

    // sort dataInvoiceValueByClient by total_amount_invoice
    Object.entries(dataInvoiceValueByClient).sort((a, b) => b[1] - a[1]);


    const chartData = Object.entries(dataInvoiceValueByClient)
        .slice(0, 5)
        .map(([client, total_amount_invoice], index) => {
            const percentage = (total_amount_invoice / totalInvoiceValue) * 100;
            const fill = blueberryTwilightPalette[index];

            return {
                id: index,
                name: client,
                value: total_amount_invoice,
                totalInvoiceValue,
            };
        });

    // add in chartData the other clients that are not in the top 5
    const otherClients = Object.entries(dataInvoiceValueByClient)
        .slice(5)
        .reduce((acc, [client, total_amount_invoice]) => acc + total_amount_invoice, 0);

    chartData.push({
        id: 5,
        name: 'Other Clients',
        value: otherClients,
        totalInvoiceValue
    });


    const columns = [
        {
            field: 'client',
            headerName: 'Client',
            // width: 200,
            flex: 1,
            editable: false,
            groupable: false,
            aggregable: false,
        },
        {
            field: 'total_amount_invoice',
            headerName: 'Total Invoice ($)',
            minWidth: 130,

            editable: false,
            groupable: false,
            aggregable: true,
            valueGetter: (value, row) => {
                const us_per_unit = row?.commercial_invoice_item?.us_per_unit || null;
                return us_per_unit;
            },
            renderCell: (params) => {
                const value = params.row.total_amount_invoice;
                return (
                    <div>
                        {value?.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                    </div>
                );
            }
        },
    ];
    // if not orders, return a centered message that there is no order for this period
    if (rows.length === 0) {
        return (
            <div className='h-100'>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                    <Typography variant="body1" align="center">
                        No data for this period. Please try a different date range.
                    </Typography>
                </div>
            </div>
        )
    }
    return (

        <Grid container spacing={isXs ? 1 : 2} justifyContent="space-around" alignItems="center" padding={1}>
            <Grid item xs={12} md={5} padding={3} order={{ xs: 2, md: 1 }}>
                <DataGridPremium
                    rows={rows}
                    columns={columns}
                    pagination
                    autoHeight
                    disableSelectionOnClick
                    autosizeOptions={{
                        columns: ['total_amount_invoice', 'client'],
                        includeOutliers: true,
                        includeHeaders: true,
                    }}
                    autosizeOnMount
                    padding={2}
                    rowHeight={40}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 15 } },
                        pinnedColumns: { left: ['picture'], right: ['actions'] },

                    }}
                    pageSizeOptions={[15, 25, 50, 100]}
                />
            </Grid>
            <Grid item xs={12} md={6} padding={1} order={{ xs: 1, md: 2 }}>
                {/* smaller than md, show an title: "Incoming per client" */}
                {isMdDown && (
                    <Typography variant="h6" align="center">
                        Incoming per client
                    </Typography>
                )}
                <PieChart
                    data={chartData}
                    CustomTooltipComponent={() => <ClientIncomingTooltip />}
                />
            </Grid>
        </Grid>

    )
}

export default ClientIncoming