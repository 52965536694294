import PhotoGallery from './components/PhotoGallery';
import { columnsData } from './variables/columnsData';
import { useState } from "react";
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { API_BASE_URL } from 'config';
import Statistics from "./components/Statistics";
import { MdOutlineNoPhotography } from "react-icons/md";
import ProductInfo from './components/Info';
import { BsPencilSquare, BsTrash } from "react-icons/bs";
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ProductAnalytics from './components/ProductAnalytics';
import useAuth from "hooks/useAuth";


const ProductPage = () => {
  const resource = 'product';
  const { id } = useParams();
  const [resourceData, setResourceData] = useState();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [tabValue, setTabValue] = useState('1');
  const [message, setMessage] = useState({ title: "Success", description: "", display: false });
  const navigate = useNavigate();
  const { auth, authLoading } = useAuth();

  const handleDeleteClick = (id) => {
    console.log("delete id", id)
    setDeleteId(id);
    setShowDeleteModal(true);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };


  useEffect(() => {
    console.log(`${API_BASE_URL}/${resource}/${id}`)
    fetch(`${API_BASE_URL}/${resource}/${id}`)
      .then((response) => response.json())
      .then((data) => { setResourceData(data); return data })
      .then((data) => {
        console.log("columnsData", columnsData);
        console.log("data", data);
        const rowData = columnsData.map((column) => {
          const { accessor } = column;

          return {
            field: column.Header,
            value: data[accessor],
          };

        });
        console.log(rowData)
        setTableData(rowData);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [id]);

  const handleDeleteConfirmed = () => {
    setMessage(() => ({ ...message, display: false }));
    setError(() => ({ ...error, display: false }));
    fetch(`${API_BASE_URL}/${resource}/${deleteId}`, {
      method: 'DELETE',
    })
      .then((response) => {
        console.log(response);
        if (!response.ok || !response.status === 204) {
          throw new Error(response.statusText)
        }
        return response
      })
      .then((data) => {
        navigate(`/admin/${resource}/list/`)

      })
      .catch((error) => {
        // Handle any error that occurred during the delete action
        console.error(error);
        setError(() => ({
          title: "Error",
          description: error.message,
          display: true,
        }));
      })
      .finally(() => {
        // Close the delete confirmation modal
        setShowDeleteModal(false);
        setDeleteId(null);
      });
  };

  if (loading | authLoading) return <div>Loading...</div>;

  return (
    <div className="mt-3 grid h-full w-full grid-cols-12 gap-3 rounded-[20px] bg-white bg-clip-border p-[18px] shadow-3xl shadow-shadow-500 dark:shadow-none dark:!bg-navy-800">
      {/* Delete Modal */}
      {showDeleteModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 shadow">
          <div className="bg-white dark:bg-navy-800 rounded-lg p-8  shadow">
            <h3 className="text-lg font-medium mb-4">
              Are you sure you want to delete it?
            </h3>
            <div className="flex justify-end">
              <button
                className="mr-2 text-sm font-medium text-red-500 hover:text-red-700"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </button>
              <button
                className="text-sm font-medium text-brand-500 hover:text-red-700"
                onClick={handleDeleteConfirmed}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="col-span-12 h-full w-full flex items-center justify-center md:col-span-4">
        {/* image */}
        <div className='flex flex-col items-center justify-center gap-3 w-full h-full'>
          {resourceData.picture ? (
            <PhotoGallery
              picture={resourceData.picture}
            />
          ) : (
            <div className="h-[150px] w-[80%] rounded-[20px] bg-gradient-to-br from-brandLinear to-blueSecondary lg:h-[390px]">
              {/* Add a text saying no image in the center */}
              <div className="flex items-center justify-center h-full w-full">
                <MdOutlineNoPhotography className="text-9xl text-white" />
              </div>
            </div>
          )}
          {/* Hide it on smaller than lg */}
          {auth?.groups?.find(role => ["Administrator", "Staff"]?.includes(role)) && (
            <div className="flex flex-row gap-8 mx-auto my-4 text-lg text-brand-500 underline hover:cursor-pointer dark:text-white visible lg:invisible ">
              <Link to={`/admin/${resource}/edit/${resourceData.id}`}>
                <div className='flex flex-row gap-1 items-center justify-center'>
                  <BsPencilSquare /> Edit
                </div>
              </Link>
              <Link onClick={() => handleDeleteClick(resourceData.id)}>
                <div className='flex flex-row gap-1 items-center justify-center'>
                  <BsTrash /> Delete
                </div>
              </Link>
            </div>
          )}

        </div>


      </div>
      <div className="col-span-12 w-full md:col-span-7">
        <ProductInfo
          data={resourceData}
          resource={resource}
        />
      </div>

      <div className="col-span-12 w-full">
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleTabChange} aria-label="lab API tabs example">
              <Tab label="Details" value="1" />
              {auth?.groups?.find(role => ["Administrator", "Staff"]?.includes(role)) && (
                <Tab label="Analytics" value="2" />
              )}
            </TabList>
          </Box>
          <TabPanel value="1" >
            <div className="col-span-12 w-full">
              <div className="col-span-12 w-full grid grid-cols-12 gap-2 p-2 m-1">

                <div className="col-span-6 md:col-span-3">
                  <Statistics title="NCM/HSCODE" value={resourceData.hs_code_or_ncm} />
                </div>
                <div className="col-span-6 md:col-span-3">
                  <Statistics title="Pcs per ctn" value={resourceData.unit_per_ctn} />
                </div>
                <div className="col-span-6 md:col-span-3">
                  <Statistics title="Nw per ctn" value={resourceData.nw_per_ctn} />
                </div>
                <div className="col-span-6 md:col-span-3">
                  <Statistics title="Gw per ctn" value={resourceData.gw_per_ctn} />
                </div>
              </div>

              <div className="col-span-12 w-full grid grid-cols-12 gap-2 p-2 m-1">
                <div className="col-span-6 md:col-span-4">
                  <Statistics title="HEIGHT/CTN" value={resourceData.master_box_height} />
                </div>
                <div className="col-span-6 md:col-span-4">
                  <Statistics title="LENGTH/CTN" value={resourceData.master_box_length} />
                </div>
                <div className="col-span-6 md:col-span-4">
                  <Statistics title="WIDTH/CTN" value={resourceData.master_box_width} />
                </div>
              </div>

              {/* Hide it on smaller than lg */}
              {auth?.groups?.find(role => ["Administrator", "Staff"]?.includes(role)) && (
                <div className="flex flex-row gap-8 mx-auto my-4 text-lg text-brand-500 underline hover:cursor-pointer dark:text-white lg:visible invisible ">
                  <Link to={`/admin/${resource}/edit/${resourceData.id}`}>
                    <div className='flex flex-row gap-1 items-center justify-center'>
                      <BsPencilSquare /> Edit
                    </div>
                  </Link>

                  <Link onClick={() => handleDeleteClick(resourceData.id)}>
                    <div className='flex flex-row gap-1 items-center justify-center'>
                      <BsTrash /> Delete
                    </div>
                  </Link>
                </div>
              )}
            </div>

          </TabPanel>
          {auth?.groups?.find(role => ["Administrator", "Staff"]?.includes(role)) && (
            <TabPanel value="2">
              <ProductAnalytics product_id={id} />
            </TabPanel>
          )}
        </TabContext>
      </div>
    </div>

  );
};

export default ProductPage;
