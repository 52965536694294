import React, { useState } from "react";
import Card from 'components/card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import { useFetchData } from 'services/queries';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';


const StyledCard = styled(Card)(({ theme }) => ({
    minWidth: 230,
    borderRadius: '16px',
    boxShadow: '0 4px 16px 0 rgba(0,0,0,0.2)',
    transition: '0.3s',
    '&:hover': {
        boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
    },
    marginTop: theme.spacing(2),
    padding: theme.spacing(0),
}));

const Title = styled(Typography)(({ theme }) => ({
    fontSize: 14,
    color: theme.palette.text.secondary,
}));

const Amount = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    fontSize: '1.5rem',
}));

const SubInfo = styled(Typography)(({ theme }) => ({
    fontSize: '0.9rem',
    color: theme.palette.text.secondary,
}));


const HeaderBoxes = (props) => {
    const { startDate, endDate } = props;
    let params_url = ''
    if (startDate || endDate) {
        params_url = `?issue_date__gte=${startDate}&issue_date__lte=${endDate}`
    }
    const { data: dataOpenOrder, error: errorDataOpenOrder, isLoading: isLoadingDataOpenOrder } = useFetchData(`order/sales-order/open-orders/${params_url}`, true);
    const { data: dataClosedOrder, error: errorDataClosedOrder, isLoading: isLoadingDataClosedOrder } = useFetchData(`order/sales-order/finished-orders/${params_url}`, true);
    const { data: dataGrossMargin, error: errorDataGrossMargin, isLoading: isLoadingDataGrossMargin } = useFetchData(`order/sales-order/get-gross-margin/${params_url}`, true);
    const { data: dataAverageTicket, error: errorDataAverageTicket, isLoading: isLoadingDataAverageTicket } = useFetchData(`order/sales-order/get-average-ticket/${params_url}`, true);
    if (isLoadingDataOpenOrder || isLoadingDataClosedOrder || isLoadingDataGrossMargin || isLoadingDataAverageTicket) {
        return <div>Loading...</div>;
    }
    if (errorDataOpenOrder || errorDataClosedOrder || errorDataGrossMargin || errorDataAverageTicket) {
        return <div>Error...</div>;
    }
    console.log("dataOpenOrder", dataOpenOrder);
    console.log("dataClosedOrder", dataClosedOrder);
    console.log("dataGrossMargin", dataGrossMargin);
    console.log("dataAverageTicket", dataAverageTicket);
    return (
        <div>
            <Grid container spacing={1} justifyContent="space-around" alignItems="center" padding={1}>
                <Grid xs={10} md={6} lg={3}>
                    <StyledCard>
                        <CardContent>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Box>
                                    <Title>Openned Orders</Title>
                                    <Amount>{dataOpenOrder.total_value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</Amount>
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    bgcolor="primary.main"
                                    color="primary.contrastText"
                                    borderRadius="50%"
                                    width="40px"
                                    height="40px"
                                    marginRight="10px"
                                >
                                    {dataOpenOrder.count}
                                </Box>
                            </Box>
                        </CardContent>
                    </StyledCard>
                </Grid>

                <Grid xs={10} md={6} lg={3}>
                    <StyledCard>
                        <CardContent>
                            <Box display="flex" justifyContent="space-between" alignItems="center">

                                <Box>
                                    <Title>Finished Orders</Title>
                                    <Amount>{dataClosedOrder.total_value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</Amount>
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    bgcolor="primary.main"
                                    color="primary.contrastText"
                                    borderRadius="50%"
                                    width="40px"
                                    height="40px"
                                    marginRight="10px"
                                >
                                    {dataClosedOrder.count}
                                </Box>
                            </Box>
                        </CardContent>
                    </StyledCard>
                </Grid>
                <Grid xs={10} md={6} lg={3}>
                    <StyledCard>
                        <CardContent>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Box>
                                    <Title>Gross Margin</Title>
                                    <Amount>{dataGrossMargin.gross_margin.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</Amount>
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    bgcolor="primary.main"
                                    color="primary.contrastText"
                                    borderRadius="50%"
                                    width="40px"
                                    height="40px"
                                    marginRight="10px"
                                >
                                    $
                                </Box>
                            </Box>
                        </CardContent>
                    </StyledCard>
                </Grid>
                <Grid xs={10} md={6} lg={3}>
                    <StyledCard>
                        <CardContent>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Box>
                                    <Title>Average Ticket / Order</Title>
                                    <Amount>{dataAverageTicket.average_ticket.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</Amount>
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    bgcolor="primary.main"
                                    color="primary.contrastText"
                                    borderRadius="50%"
                                    width="40px"
                                    height="40px"
                                    marginRight="10px"
                                >
                                    $
                                </Box>
                            </Box>
                        </CardContent>
                    </StyledCard>
                </Grid>


            </Grid>
        </div>
    )
}

export default HeaderBoxes