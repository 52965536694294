const getInputChange = (e, formData, setFormData) => {
    const { id, value, type, checked } = e.target;

    if (type === "checkbox") {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [id]: checked,
        }));
    } else if (id.includes(".")) {
        const nestedFields = id.split(".");
        let updatedFormData = { ...formData };
        let currentField = updatedFormData;

        for (let i = 0; i < nestedFields.length - 1; i++) {
            const field = nestedFields[i];
            currentField = currentField[field] || {};
        }

        const lastField = nestedFields[nestedFields.length - 1];
        currentField[lastField] = value;

        setFormData(updatedFormData);
    } else {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [id]: value,
        }));
    }
};

export default getInputChange;