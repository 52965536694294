import DynamicFormField from "components/form/DynamicFormField";
import dayjs from 'dayjs';
import { useFetchData, useDownloadData } from "services/queries";
import { useParams } from "react-router-dom";
import { Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Menu, MenuItem, Hidden } from '@mui/material';
import React, { useEffect } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import MenuList from '@mui/material/MenuList';
import ListItemIcon from '@mui/material/ListItemIcon';
import Check from '@mui/icons-material/Check';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import DownloadIcon from '@mui/icons-material/Download';
import getInputChange from "utils/handleInputChange";
import { useState } from "react";
import { useUpdateData, useFetchDataId, useCreateData } from "services/queries";
import { useNavigate } from "react-router-dom";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import toast from "react-hot-toast";

import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent } from '@mui/lab';
import { Paper, Typography } from '@mui/material';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import {
    timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { TreeItem2Icon } from '@mui/x-tree-view/TreeItem2Icon';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ListItem, ListItemText } from '@mui/material';

const TimelineComponent = ({ sales_order_id }) => {
    const { data: notes, error: errorNotes, isLoading: isLoadingNotes, refetch: refetchNotes } = useFetchData(`notes/note/?sales_order=${sales_order_id}`, true);
    const { data: productNotes, error: errorProductNotes, isLoading: isLoadingProductNotes, refetch: refetchProductNotes } = useFetchData(`notes/note/?sales_order__products=${sales_order_id}`, true);
    const { data: products, isLoading: isLoadingProducts, error: errorProducts } = useFetchData('product', true);
    // get order data
    const { data: order, error: errorOrder, isLoading: isLoadingOrder } = useFetchDataId('order/simple-sales-order', sales_order_id, true);
    const createNote = useCreateData('notes/note');

    const [formData, setFormData] = useState({ sales_order: sales_order_id });
    const handleInputChange = (e) => (getInputChange(e, formData, setFormData));

    const handleSave = () => {
        setFormData(() => ({ ...formData, sales_order: sales_order_id }));
        createNote.mutate(formData);
    };

    const formFields = [
        {
            id: "note",
            label: "Add New Note...",
            type: "textarea",
            mdColSpan: 12,
            handleInputChange,
            value: formData?.note
        },
        {
            id: "product",
            label: "Product - Optional",
            type: "select",
            options: products?.map(product => ({ value: product.id, label: `${product.sku} - ${product.name}` })),
            mdColSpan: 12,
            handleInputChange: handleInputChange,
            value: formData?.product
        }
    ]

    useEffect(() => {
        refetchNotes();
        setFormData(() => ({ sales_order: sales_order_id }));
    }, [notes, createNote.isSuccess]);
    if (isLoadingNotes || isLoadingProducts || isLoadingOrder || isLoadingProductNotes) {
        return <div>Loading...</div>;
    }
    if (errorNotes || errorProducts || errorOrder || errorProductNotes) {
        return <div>Error...</div>;
    }

    // concat notes and productNotes
    const notesData = [...notes, ...productNotes];
    // sort it by desc by created_at
    notesData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    console.log("notesData: ", notesData);
    return (
        <>
            <Grid container spacing={2} margin={2} display="flex" justifyContent="center" alignItems="center">
                <Grid xs={12} md={11}>
                    {formFields.map(field => {
                        return (
                            <DynamicFormField
                                key={field.id}
                                field={field}
                                formData={formData}
                                mdColSpan={11}
                                handleInputChange={handleInputChange}
                            />
                        );
                    })}
                </Grid>
                <Grid item md={1}>
                    <Button onClick={handleSave} variant="contained" color="primary">
                        Add
                    </Button>
                </Grid>
            </Grid>
            <Timeline
                sx={{
                    [`& .${timelineOppositeContentClasses.root}`]: {
                        flex: 0.25,
                    },
                }}
            >
                {notesData.map((item, index) => (
                    <TimelineItem key={index}>
                        <TimelineOppositeContent color="text.secondary">
                            <Typography component="span" variant="button" display="block" margin={0}>
                                {item.created_by?.name}
                            </Typography>
                            <Typography variant="overline" display="block" >
                                {dayjs(item.created_at).format('YYYY-MM-DD HH:mm')}
                            </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot color="secondary" />
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>

                            <Typography variant="caption" display="block" color="text.secondary">
                                {order.id === item.sales_order ? <span style={{ fontWeight: 'bold' }}>{order.order_identifier}</span> : ''}
                                {item.product && order.id === item.sales_order && ' - '}
                                {item.product && (
                                    <span style={{ fontStyle: 'italic' }}>
                                        {`${products.find(product => product.id === item.product)?.name || 'N/A'} [${products.find(product => product.id === item.product)?.sku || 'N/A'}]`
                                        }
                                    </span>
                                )}
                            </Typography>

                            <Typography sx={{ whiteSpace: 'pre-wrap' }}>
                                {item.note}
                            </Typography>
                        </TimelineContent>
                    </TimelineItem>
                ))}
            </Timeline>
        </>
    );
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function FullScreenDialog({ open, onClose, sales_order_id }) {


    const handleClose = () => {
        onClose();
    };

    return (
        <React.Fragment>
            <Dialog
                fullWidth
                maxWidth="md"
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Notes
                        </Typography>
                        <Button autoFocus color="inherit" onClick={handleClose}>
                            Close
                        </Button>
                    </Toolbar>
                </AppBar>
                <TimelineComponent sales_order_id={sales_order_id} /> {/* Use your TimelineComponent here */}
            </Dialog>
        </React.Fragment>
    );
}

const OrderSale = () => {
    const simple_resource_path = 'order/simple-sales-order';
    const resource_path = 'order/sales-order';
    const { id } = useParams();
    const isEditMode = Boolean(id);
    const navigate = useNavigate();
    const { data: clients, isLoading: isLoadingClients, error: errorClients } = useFetchData('client', true);
    const { data: shippers, isLoading: isLoadingShippers, error: errorShippers } = useFetchData('shipper', true);
    const { data: statusSalesOrder, isLoading: isLoadingStatusSalesOrder, error: errorStatusSalesOrder } = useFetchData('order/status-sales-order', true);
    const { data: orderFiles, error: errorOrderFiles, isLoading: isLoadingOrderFiles, refetch: refetchOrderFiles } = useFetchData(`order/sales-order-files/?file_type=FULL&sales_order=${id}`, true, !!id);
    const { data: packingFiles, error: errorPackingFiles, isLoading: isLoadingPackingFiles, refetch: refetchPackingFiles } = useFetchData(`order/sales-order-files/?file_type=PL&sales_order=${id}`, true);
    const { data: invoiceFiles, error: errorInvoiceFiles, isLoading: isLoadingInvoiceFiles, refetch: refetchInvoiceFiles } = useFetchData(`order/sales-order-files/?file_type=CI&sales_order=${id}`, true);
    const { data: order, error: errorOrder, isLoading: isLoadingOrder } = useFetchDataId(simple_resource_path, id, true);
    const { data: users, error: errorUsers, isLoading: isLoadingUsers } = useFetchData('authentication/users', true);

    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const [showNotes, setShowNotes] = useState(false);
    const toggleNotes = () => setShowNotes(!showNotes);


    const downloadOrderExcel = useDownloadData(`order/generate-excel-sales-order/${id}`, true);
    const downloadPackingListPDF = useDownloadData(`order/generate-pdf-packing-list/${id}`, true);
    const downloadInvoiePDF = useDownloadData(`order/generate-pdf-commercial-invoice/${id}/`, true);

    const updateOrder = useUpdateData(`${resource_path}`);
    const createOrder = useCreateData(`${resource_path}`);

    const duplicateOrder = useCreateData(`order/sales-order/${id}/duplicate-order`);

    const handleDuplicateOrder = () => {
        duplicateOrder.mutate(id);
    };

    const [formData, setFormData] = useState({});
    const handleInputChange = (e) => (getInputChange(e, formData, setFormData));


    useEffect(() => {
        setFormData(() => order);
    }, [order]);

    useEffect(() => {
        // navigate to the new order after duplicate
        if (duplicateOrder.isSuccess) {
            toast.success('Order duplicated successfully.');
            navigate(`/admin/order/sales-order/edit/${duplicateOrder.data.id}`)
        }
    }, [duplicateOrder.isSuccess, navigate, duplicateOrder.data?.id]);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSave = () => {
        isEditMode ? updateOrder.mutate({ id, newData: formData }) : createOrder.mutate(formData);
    };

    useEffect(() => {
        if (createOrder.isSuccess) {
            // navigate("/admin/order/sales-order/tracking/");
            navigate(`/admin/order/sales-order/edit/${createOrder.data.id}`)
        }
    }, [updateOrder.isSuccess, createOrder.isSuccess, navigate, createOrder.data?.id, isEditMode]);

    const handleOrderExcelDownload = () => {
        downloadOrderExcel.mutate('myDownloadedFile.pdf');
    };

    useEffect(() => {
        refetchOrderFiles();
    }, [downloadOrderExcel.isSuccess]);

    useEffect(() => {
        refetchPackingFiles();
    }, [downloadPackingListPDF.isSuccess]);

    useEffect(() => {
        refetchInvoiceFiles();
    }, [downloadInvoiePDF.isSuccess]);

    const handleOrderPackingListPDFDownload = () => {
        downloadPackingListPDF.mutate('myDownloadedFile.pdf');
    };

    const handleOrderInvoicePDFDownload = () => {
        downloadInvoiePDF.mutate('myDownloadedFile.pdf');
    };

    if (isLoadingClients || isLoadingShippers || isLoadingStatusSalesOrder || isLoadingOrder || isLoadingUsers ) {
        return <div>Loading...</div>;
    }
    if (errorClients || errorShippers || errorStatusSalesOrder || errorOrder || errorUsers) {
        return <div>Error...</div>;
    }

    const formFields = [
        {
            id: "order_identifier",
            label: "Order Identifier",
            type: "text",
            mdColSpan: 3,
            handleInputChange,
            value: formData?.order_identifier
        },
        {
            id: "issue_date",
            label: "Issue Date",
            type: "date",
            disabled: true,
            default: dayjs(new Date()).format("YYYY-MM-DD"),
            mdColSpan: 3,
            handleInputChange,
            value: formData?.issue_date
        },
        {
            id: "customer_ref",
            label: "Customer Reference",
            type: "text",
            mdColSpan: 3,
            handleInputChange,
            value: formData?.customer_ref
        },
        {
            id: "assigned_to",
            label: "Assigned To",
            type: "select",
            options: users
                ?.filter(user => user.is_active && (user.is_staff || user.is_superuser))
                .map(user => ({ value: user.id, label: user.name })),
            mdColSpan: 3,
            handleInputChange,
            value: formData?.assigned_to
        },
        {
            id: "buyer_id",
            label: "Client",
            type: "select",
            options: clients?.map(client => ({ value: client.id, label: client.social_reason })),
            mdColSpan: 6,
            handleInputChange,
            value: formData?.buyer_id
        },
        {
            id: "shipper",
            label: "Shipper",
            type: "select",
            options: shippers?.map(shipper => ({ value: shipper.id, label: shipper.social_reason })),
            mdColSpan: 6,
            handleInputChange,
            value: formData?.shipper
        },
        {
            id: "status_id",
            label: "Order Status",
            type: "select",
            options: statusSalesOrder.map(status => ({ value: status.id, label: status.status })),
            mdColSpan: 6,
            handleInputChange,
            value: formData?.status_id
        },
        {
            id: "payment_terms",
            label: "Payment Terms",
            placeholder: "eg. 100% in 30 days",
            type: "text",
            mdColSpan: 6,
            handleInputChange,
            value: formData?.payment_terms
        },
        {
            id: "discontinued_reason",
            label: "Discontinued Reason",
            type: "textarea",
            mdColSpan: 12,
            showIf: () => {
                return formData?.status_id === statusSalesOrder.find(status => status.status === 'Discontinued').id;
            }
            ,
            handleInputChange,
            value: formData?.discontinued_reason
        },
        {
            id: "additional_information",
            label: "Additional Information",
            type: "textarea",
            placeholder: "eg. Additional information about the order",
            mdColSpan: 12,
            cols: 30,
            mdCols: 1000,
            handleInputChange,
            value: formData?.additional_information
        }
    ];

    console.log("order: ", order);

    return (
        // <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
        <Grid container spacing={2} margin={2}>
            {/* Header */}
            <Grid container display="flex" justifyContent="space-between" xs={12}>
                <Grid>
                    <h4 className="pt-[5px] text-2xl font-bold text-navy-700 dark:text-white">
                        Order Info
                    </h4>
                </Grid>

                <Grid>
                    <Hidden lgDown>
                        <Button variant="contained" color="primary" onClick={handleSave}>
                            Save
                        </Button>
                    </Hidden>

                    <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        fullWidth
                        dense
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        PaperProps={{
                            style: {
                                width: isMobile ? '90%' : '40%', // Use 90% width on mobile screens, 40% otherwise
                                maxHeight: '300px',
                                overflow: 'auto'
                            }
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <MenuList dense>
                            <MenuItem onClick={handleSave}>
                                <ListItemIcon>
                                    <Check />
                                </ListItemIcon>
                                <ListItemText primary="Save" />
                            </MenuItem>

                            <MenuItem onClick={toggleNotes}>
                                <ListItemIcon>
                                    <SpeakerNotesIcon />
                                </ListItemIcon>
                                <ListItemText primary="Notes" />
                            </MenuItem>

                            <Divider />
                            <MenuItem button onClick={handleDuplicateOrder}>
                                <ListItemIcon>
                                    <ContentCopyIcon />
                                </ListItemIcon>
                                <ListItemText primary="Duplicate" />
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={handleOrderExcelDownload}>
                                <ListItemIcon>
                                    <DownloadIcon />
                                </ListItemIcon>
                                <ListItemText primary="Download Excel" />
                            </MenuItem>
                            <MenuItem onClick={handleOrderPackingListPDFDownload}>
                                <ListItemIcon>
                                    <DownloadIcon />
                                </ListItemIcon>
                                <ListItemText primary="Packing List" />
                            </MenuItem>

                            <MenuItem onClick={handleOrderInvoicePDFDownload}>
                                <ListItemIcon>
                                    <DownloadIcon />
                                </ListItemIcon>
                                <ListItemText primary="Invoice" />
                            </MenuItem>

                        </MenuList>
                        {(orderFiles?.length > 0 || packingFiles?.length > 0 || invoiceFiles?.length > 0) && (
                            <>
                                <Divider />
                                <SimpleTreeView>
                                    {orderFiles?.length > 0 && (
                                        <TreeItem itemId="gridOrderFiles" label="Excel Files">
                                            {orderFiles?.map(file => (
                                                <TreeItem
                                                    itemId={file.id}
                                                    slots={{ icon: DownloadIcon }}
                                                    label={file.file_name} onClick={handleOrderExcelDownload} />
                                            ))}
                                        </TreeItem>
                                    )}
                                    {packingFiles?.length > 0 && (
                                        <TreeItem itemId="gridPackingFiles" label="Packing List">
                                            {packingFiles?.map(file => (
                                                <TreeItem
                                                    itemId={file.id}
                                                    slots={{ icon: DownloadIcon }}
                                                    label={file.file_name}
                                                    onClick={handleOrderPackingListPDFDownload} />
                                            ))}
                                        </TreeItem>
                                    )}
                                    {invoiceFiles?.length > 0 && (
                                        <TreeItem itemId="gridInvoiceFiles" label="Invoice">
                                            {invoiceFiles?.map(file => (
                                                <TreeItem
                                                    itemId={file.id}
                                                    slots={{ icon: DownloadIcon }}
                                                    label={
                                                        <Typography variant="body2" color="text.secondary">
                                                            {file.file_name}
                                                        </Typography>
                                                    }
                                                    onClick={handleOrderInvoicePDFDownload}
                                                />
                                            ))}
                                        </TreeItem>
                                    )}

                                </SimpleTreeView>
                            </>
                        )}
                    </Menu>
                    <FullScreenDialog open={showNotes} onClose={toggleNotes} sales_order_id={id} />

                </Grid>

            </Grid>
            {/* content */}
            {/* inputs */}
            <Grid container spacing={2}>
                {formFields.map(field => {
                    return (
                        <DynamicFormField
                            key={field.id}
                            field={field}
                            formData={formData}
                            handleInputChange={handleInputChange}
                        />
                    );
                })}
            </Grid>
        </Grid >
    );
}

export default OrderSale