import { useEffect, useState } from "react";
import React from 'react';
import {
    GridRowModes,
    DataGridPremium,
    GridActionsCellItem,
    GridRowEditStopReasons,
    GRID_AGGREGATION_ROOT_FOOTER_ROW_ID,
    GridToolbarQuickFilter,
    GridToolbarExport
} from '@mui/x-data-grid-premium';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useFetchData, useCreateData, useUpdateData, useDeleteData } from 'services/queries';
import { Stack, Box, useMediaQuery } from '@mui/material';

const EditableDataGrid = ({ tableData, setTableData, resourcePath, relatedResources, columns, newRowTemplate, apiRef, actions, ...dataGridProps }) => {
    const [rowModesModel, setRowModesModel] = useState({});
    const { data, isLoading, error, refetch } = useFetchData(relatedResources, true);
    const deleteData = useDeleteData(`${resourcePath}`);
    const updateData = useUpdateData(`${resourcePath}`);
    const isMobile = useMediaQuery('(max-width: 600px)'); // Check if screen size is mobile

    // if no actons are passed, set default actions
    if (!actions) {
        actions = {
            create: true,
            edit: true,
            delete: true,
            search: true,
        }
    } else if (actions.search === undefined) {
        actions.search = true;
    }

    const createData = useCreateData(`${resourcePath}`);

    function CustomToolbar(props) {
        const { setRows, setRowModesModel } = props;

        const handleClick = () => {
            const id =  -Math.floor(Math.random() * 1000);
            const newRow = { ...newRowTemplate, id, isNew: true };
            setTableData((oldRows) => [newRow, ...oldRows]);
            setRowModesModel((oldModel) => ({
                ...oldModel,
                [id]: { mode: GridRowModes.Edit },
            }));
        };

        return (
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
                m={1}
            >

                {actions.search && (
                    <Box color="secondary" display="flex" justifyContent="flex-end" alignItems="flex-start" >
                        <GridToolbarQuickFilter />
                    </Box>
                )}
                {actions.create && (
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={3}
                    >
                        <Button
                            variant="contained"
                            display="flex"
                            color="primary"
                            startIcon={<AddIcon />} onClick={handleClick}>
                            {isMobile ? null : "Add New"}
                        </Button>
                    </Stack>
                )}

                {actions.export && (
                    <Box color="secondary" display="flex" justifyContent="flex-end" alignItems="flex-start" >
                        <GridToolbarExport />
                    </Box>
                )}



            </Stack >
        );
    }
    useEffect(() => {
        refetch();
        setTableData(data || []);
    }, [data]);


    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };


    const handleSaveClick = (id) => () => {
        setRowModesModel((prevModel) => ({ ...prevModel, [id]: { mode: GridRowModes.View } }));
    };

    const handleEditClick = (id) => () => {
        setRowModesModel((prevModel) => ({ ...prevModel, [id]: { mode: GridRowModes.Edit } }));
    };

    const handleDeleteClick = (id) => async () => {
        const response = await deleteData.mutateAsync(id);
        setTableData((oldRows) => oldRows.filter((row) => row.id !== id));
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel((prevModel) => ({ ...prevModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } }));

        const editedRow = tableData.find((row) => row.id === id);
        if (editedRow.isNew) {
            setTableData((oldRows) => oldRows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = React.useCallback(
        async (newRow, originalRow) => {
            let updatedRow = { ...newRow, isNew: false };

            if (newRow.isNew) {
                const response = await createData.mutateAsync(newRow);
                updatedRow = { ...response, isNew: false };
                setTableData((oldRows) => oldRows.map((row) => (row.id === originalRow.id ? updatedRow : row)));

            } else {
                const response = await updateData.mutateAsync({ id: updatedRow.id, newData: updatedRow });
                updatedRow = { ...response };
                setTableData((oldRows) => oldRows.map((row) => (row.id === originalRow.id ? updatedRow : row)));
            }
            return updatedRow;
        }, [createData, updateData]);

    const handleProcessRowUpdateError = React.useCallback((error) => {
        // toast.error(error.message || 'An error occurred');
        console.error(error);
    }, []);

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const getActions = (params) => {
        if (params.id === GRID_AGGREGATION_ROOT_FOOTER_ROW_ID) {
            return [];
        }
        const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
            return [
                <GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(params.id)} />,
                <GridActionsCellItem icon={<CancelIcon />} label="Cancel" onClick={handleCancelClick(params.id)} />
            ];
        }
        const list_actions = [];
        if (actions.edit) {
            list_actions.push(<GridActionsCellItem icon={<EditIcon />} label="Edit" onClick={handleEditClick(params.id)} />);
        }
        if (actions.delete) {
            list_actions.push(<GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(params.id)} />);
        }
        return list_actions;
    }

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;
    if (columns.length === 0) return <div>No columns</div>;

    // concat actions column
    if (actions.edit || actions.delete) {
        const width_actions = actions.edit && actions.delete ? 100 : 65;
        columns = [
            {
                field: 'actions',
                headerName: '',
                type: 'actions',
                disableColumnMenu: true,
                hideSortIcons: true,
                renderCell: getActions,
                width: width_actions
            }
        ].concat(columns);
    }

    // set valueGetter, valueSetter and valueFormatter if type = date
    const addDatePropertiesToColumns = (columns) => {
        return columns.map(column => {
            const hasValueGetter = column.hasOwnProperty('valueGetter');
            const hasValueSetter = column.hasOwnProperty('valueSetter');
            const hasValueFormatter = column.hasOwnProperty('valueFormatter');
            if (column.type === 'date') {
                return {
                    ...column,
                    valueGetter: (value, row) => {
                        if (hasValueGetter)
                            return column.valueGetter(value, row);
                        return value ? new Date(value + "T12:00:00") : null;
                    },

                    valueSetter: (value, row) => {
                        if (hasValueSetter) return column.valueSetter(value, row);
                        const oldValue = row.date;
                        if (value instanceof Date) {
                            return { ...row, date: value.toISOString().split('T')[0] };  // Converts Date object back to string in 'YYYY-MM-DD' format
                        }
                        return { ...row, date: oldValue };  // Keeps the old value if the new value isn't a Date instance
                    },
                    valueFormatter: (value) => {
                        if (hasValueFormatter) return column.valueFormatter(value);
                        if (value == null) {
                            return '';
                        }
                        //   format the date to 'YYYY-MM-DD'
                        if (value instanceof Date) {
                            return value.toISOString().split('T')[0];
                        }
                        return value;
                    }
                };
            }
            return column;
        });
    };

    // Apply date properties to columns
    columns = addDatePropertiesToColumns(columns);

    if (!actions.edit) {
        columns = columns.map(column => {
            column.editable = false;
            return column;
        });
    }


    if (!actions.edit) {
        columns = columns.map(column => {
            column.editable = false;
            return column;
        });
    }


    return (
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr' }}>
            <DataGridPremium
                cellSelection
                apiRef={apiRef} // Pass the apiRef to the DataGridPremium component
                rows={tableData}
                pagination

                pageSizeOptions={[10, 25, 50, 100]}
                disableSelectionOnClick
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                slots={{
                    toolbar: CustomToolbar,
                }}
                slotProps={{
                    toolbar: { setTableData, setRowModesModel },
                }}
                disableRowSelectionOnClick
                {...dataGridProps} // Pass the DataGrid props as a spread operator
                componentsProps={{
                    cell: {
                        error: true,
                        helperText: (params) => params.row.isError ? params.row.errorMessage : '',
                    },
                }}
            />
        </Box>
    )
}

export default EditableDataGrid