import React, { useEffect, useState, useContext } from "react";
import Card from 'components/card';
import { API_BASE_URL } from 'config';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import UploadIcon from '@mui/icons-material/Upload';
import CheckIcon from '@mui/icons-material/Check';
import {
    DataGridPremium,
    GridToolbarContainer,
} from '@mui/x-data-grid-premium';
import * as XLSX from "xlsx";
import Tooltip from '@mui/material/Tooltip';
import AuthContext from 'contexts/AuthProvider';
import { useLoading } from 'contexts/LoadingContext';
import { useNotification } from 'contexts/NotificationContext'
import { useFetchData } from 'services/queries';

import JSZip from 'jszip';

function truncateFloatNumber(number) {
    const numberString = number.toString();
    const decimalPlaces = numberString.split('.')[1];
    if (decimalPlaces && decimalPlaces.length > 5) {
        return parseFloat(number).toFixed(5);
    }
    return number;
}

const LoadData = () => {
    const resource = 'product';
    const [rows, setRows] = useState([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const { apiRequest } = useContext(AuthContext);
    const { setLoading } = useLoading();
    const { setNotification } = useNotification();
    const { data: categories, error: errorCategories, isLoading: isLoadingCategories } = useFetchData('product/category');
    const { data: products, error: errorProducts, isLoading: isLoadingProducts } = useFetchData('product');

    useEffect(() => {
        setIsDataLoaded(() => rows.length > 0);
    }, [rows]);

    function EditToolbar(props) {
        return (
            <GridToolbarContainer>
                <Tooltip title="Upload a Excel file">
                    <Button
                        startIcon={<UploadIcon />}
                        component="label"
                        sx={{
                            opacity: isDataLoaded ? 0.4 : 1,
                            pointerEvents: isDataLoaded ? 'none' : 'auto',
                        }}
                    >
                        Upload File
                        <input type="file" accept=".zip" hidden onChange={handleZipUpload} />
                    </Button>
                </Tooltip>

                {isDataLoaded && (
                    <Tooltip title="Import data to the system">
                        <Button startIcon={<CheckIcon />} color="info" onClick={handleDataLoad} >Import it </Button>
                    </Tooltip>
                )}
            </GridToolbarContainer>
        );
    }

    const handleDataLoad = async () => {
        try {
            setLoading(true);
            const updatedRows = await Promise.all(rows.map(async (row) => {
                try {
                    const responseData = await apiRequest(`${API_BASE_URL}/${resource}/?sku=${row.SKU}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });

                    const category = categories.find((category) => category.name === row.Category);
                    const category_id = category ? category.id : null;

                    if (!category_id) {
                        return {
                            ...row,
                            STATUS: 'ERROR',
                            MESSAGE: { 'Error': ['Category not found.'] }
                        };
                    }

                    const data = {
                        name: row.Name,
                        sku: row.SKU,
                        category_id: category_id,
                        short_description: row.Description,
                        hs_code_or_ncm: row['NCM/HSCODE'],
                        type: row.Type,
                        unit_per_ctn: row['PCS/CTN'],
                        nw_per_ctn: truncateFloatNumber(row['NW/CTN']),
                        gw_per_ctn: truncateFloatNumber(row['GW/CTN']),
                        master_box_height: row['HEIGHT/CTN'],
                        master_box_length: row['LENGTH/CTN'],
                        master_box_width: row['WIDTH/CTN'],
                    };
                    if (row.picture) {
                        data['picture'] = row.picture;
                    }

                    console.log(data);
                    const formData = new FormData();
                    for (const [key, value] of Object.entries(data)) {
                        if (value) {
                            formData.set(key, value);
                        }
                    }
                    if (responseData.status_code === 200 && responseData.results.length === 1) {
                        // product already exists, update it with PUT request
                        const putResponse = await updateProduct(responseData.results[0].id, formData, 'PUT');
                        const putResponseData = putResponse.data;

                        if (putResponse.status_code !== 200) {
                            console.error('putResponse:', putResponse);
                            console.error('Error:', putResponseData);
                            return {
                                ...row,
                                MESSAGE: putResponseData,
                                STATUS: 'ERROR',
                            };
                        }

                        return {
                            ...row,
                            STATUS: 'UPDATED',
                        };
                    } else if (responseData.status_code === 404 || responseData.results.length === 0) {
                        // product does not exist, create it with POST request
                        const postResponse = await updateProduct('', formData, 'POST');
                        const postResponseData = postResponse.data;

                        if (postResponse.status_code !== 201) {
                            console.error("LOGGING ERRORS FOR PRODUCT SKU ", row.SKU);
                            for (var pair of formData.entries()) {
                                console.error(pair[0] + ', ' + pair[1]);
                            }
                            console.error('postResponse:', postResponse);
                            let message = {}; // Change this to an object
                            let errorMessage = '';
                            Object.keys(postResponse).forEach(key => {
                                if (key !== 'status_code') {
                                    // Correctly assign the error message to the key
                                    message[key] = postResponse[key].join(', ');
                                }
                            });
                            if (postResponseData) errorMessage += postResponseData;

                            console.error('Error:', errorMessage);
                            return {
                                ...row,
                                STATUS: 'ERROR',
                                MESSAGE: message, // Now message is an object
                            };
                        }
                        return {
                            ...row,
                            STATUS: 'CREATED',
                        };
                    } else {
                        return {
                            ...row,
                            STATUS: 'ERROR',
                            MESSAGE: { 'Error': ['Unkown error.'] }
                        };
                    }
                } catch (error) {
                    console.error('Error:', error);
                    return {
                        ...row,
                        STATUS: 'ERROR',
                        MESSAGE: { 'Error': ['Unkown error.'] }
                    };
                }
            }));
            setRows(updatedRows);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error:', error);
            setNotification({
                title: `Problem with ${resource}`,
                display: true,
                description: "Something went wrong.",
                type: 'error'
            });
        }
    };

    const updateProduct = async (id, data, method) => {
        const url = id ? `${API_BASE_URL}/${resource}/${id}/` : `${API_BASE_URL}/${resource}/`;
        const response = await apiRequest(url, {
            method: method,
            body: data,
        });
        return response;
    };

    const handleZipUpload = async (event) => {
        try {
            setLoading(true);
            const file = event.target.files[0];
            const zip = new JSZip();
            const content = await zip.loadAsync(file);
            const excelFileName = Object.keys(content.files).find((fileName) => fileName.endsWith('.xlsx') || fileName.endsWith('.xls'));
            const imageFolderName = 'pictures/';
            const images = {};
            const file_images = {};

            if (excelFileName) {
                const excelFile = await content.files[excelFileName].async('binarystring');
                const wb = XLSX.read(excelFile, { type: 'binary' });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = XLSX.utils.sheet_to_json(ws, { header: 1, defval: '' });
                const header = data[0];
                let newRows = data.slice(1).map((row, index) => {
                    const newRow = {};
                    header.forEach((key, i) => {
                        newRow[key] = row[i];
                    });
                    newRow['STATUS'] = 'Not Exists';
                    newRow['MESSAGE'] = '';
                    newRow.id = index + 1;
                    return newRow;
                });

                // Load images from the 'pictures' folder
                await Promise.all(Object.keys(content.files).map(async (fileName) => {
                    if (fileName.includes(imageFolderName) && !fileName.endsWith('/')) {
                        const imgBlob = await content.files[fileName].async('blob'); // Convert to Blob
                        const sku = fileName.split('/').pop().split('.')[0];
                        const extension = fileName.split('.').pop();
                        const mimeType = extension === 'png' ? 'image/png' : 'image/jpeg';
                        images[sku] = URL.createObjectURL(imgBlob); // Create a URL for display in the DataGrid
                        file_images[sku] = new File([imgBlob], `${sku}.${extension}`, { type: mimeType }); // Create a File object
                    }
                }));

                // Merge images with rows
                newRows = newRows.map(row => ({
                    ...row,
                    image: images[row.SKU] || null,
                    picture: file_images[row.SKU] || null
                }));

                setRows(() => newRows);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error:', error);
        }
    };

    const columns = [
        {
            field: 'image',
            headerName: 'Image',
            width: 80,
            renderCell: (params) => (
                params.value ? <img src={params.value} alt="Product" style={{ maxWidth: '100%', maxHeight: '100%', padding: "4px" }} /> : null
            ),
        },
        { field: 'Name', headerName: 'Name', width: 180, editable: true },
        {
            field: 'SKU',
            headerName: 'SKU',
            width: 120,
        },
        {
            field: 'Category',
            headerName: 'Category',
            width: 150,
        },
        {
            field: 'Description',
            headerName: 'Description',
            width: 180,
        },
        {
            field: 'NCM/HSCODE',
            headerName: 'NCM/HSCODE',
            width: 130,
        },
        {
            field: 'Type',
            headerName: 'Type',
            width: 70,
        },
        {
            field: 'PCS/CTN',
            headerName: 'PCS/CTN',
            width: 120,
            type: "number",
        },
        {
            field: 'NW/CTN',
            headerName: 'NW/CTN',
            width: 120,
            type: "number",
        },
        {
            field: 'GW/CTN',
            headerName: 'GW/CTN',
            width: 120,
            type: "number",
        },
        {
            field: 'HEIGHT/CTN',
            headerName: 'HEIGHT/CTN',
            width: 120,
            type: "number",
        },
        {
            field: 'LENGTH/CTN',
            headerName: 'LENGTH/CTN',
            width: 120,
            type: "number",
        },
        {
            field: 'WIDTH/CTN',
            headerName: 'WIDTH/CTN',
            width: 120,
            type: "number",
        },
        {
            field: 'STATUS',
            headerName: 'STATUS',
            width: 120,
            defaultValue: 'WAITING',
            // render a tag style for different status (WAITING, SUCCESS, ERROR)
            renderCell: (params) => (
                <div className="flex items-center">
                    <span
                        className={`w-3 h-3 rounded-full inline-block mr-2 ${params.value === 'Not Exists'
                            ? 'bg-gray-500'
                            : params.value === 'Exists'
                                ? 'bg-yellow-500'
                                : (params.value === 'SUCCESS' || params.value === 'UPDATED' || params.value === 'CREATED')
                                    ? 'bg-green-500'
                                    : 'bg-red-500'
                            }`}
                    ></span>
                    {params.value}
                </div>
            ),
        },
        {
            field: 'MESSAGE',
            headerName: 'MESSAGE',
            width: 200,
            renderCell: (params) => (
                <div className="flex flex-col">
                    {params.row.MESSAGE &&
                        Object.entries(params.row.MESSAGE).map(([key, value]) => (
                            <span key={key}>
                                <b>{key}</b>: {value}
                            </span>
                        ))}
                </div>
            ),
        },
    ];

    if (isLoadingCategories | isLoadingProducts) return <div>Loading...</div>;
    if (errorCategories) return <div>Error: {errorCategories.message}</div>;
    if (errorProducts) return <div>Error: {errorProducts.message}</div>;


    return (
        <Card extra={'w-full h-full bg-white mt-3 p-3'}>
            <Box
                sx={{
                    // height: 500,
                    width: '100%',
                    '& .actions': {
                        color: 'text.secondary',
                    },
                    '& .textPrimary': {
                        color: 'text.primary',
                    },
                    '& .MuiDataGrid-root': {
                        border: 'none',
                    },
                }}
            >
                <DataGridPremium
                    rows={rows}
                    columns={columns.map(column => ({
                        ...column,
                        editable: false
                    }))}
                    slots={{
                        toolbar: EditToolbar,
                    }}
                    getRowId={(row) => row.SKU}
                    initialState={{ pinnedColumns: { left: ['STATUS', 'MESSAGE'] } }}
                    sx={{
                        '.MuiDataGrid-columnHeaderTitle': {
                            fontWeight: 'bold',
                        },
                    }}
                />
            </Box>
        </Card>
    );
}


export default LoadData;
