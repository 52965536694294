import {
  MdDashboard,
  MdLock,
  MdOutlineShoppingCart,
  MdFactCheck,
  MdPeopleAlt,
  MdBusiness,
  MdOutlineAddBusiness,
} from "react-icons/md";
import { IoBusinessOutline } from "react-icons/io5";
import { FaShip } from "react-icons/fa";


import { FaPlaneDeparture } from "react-icons/fa";



// Admin Imports
import DashboardsDefault from "views/admin/dashboards/company";
// import DashboardsDefault from "views/admin/dashboards/default";
import DashboardsRTLDefault from "views/admin/dashboards/rtl";
import DashboardsCarInterface from "views/admin/dashboards/carInterface";
import DashboardsSmartHome from "views/admin/dashboards/smartHome";

// NFT Imports
import NFTMarketplace from "views/admin/nfts/marketplace";
import NFTPage from "views/admin/nfts/page";
import NFTCollection from "views/admin/nfts/collection";
import NFTProfile from "views/admin/nfts/profile";

// Main Imports
import AccountBilling from "views/admin/main/account/billing";
import AccountApplications from "views/admin/main/account/application";
import AccountInvoice from "views/admin/main/account/invoice";
import AccountSettings from "views/admin/main/account/settings";
import AccountAllCourses from "views/admin/main/account/courses";
import AccountCoursePage from "views/admin/main/account/coursePage";

import UserNew from "views/admin/main/users/newUser";
import UsersOverview from "views/admin/main/users/overview";
import UsersReports from "views/admin/main/users/reports";

import ProfileSettings from "views/admin/main/profile/settings";
import ProfileOverview from "views/admin/main/profile/overview";
import ProfileNewsfeed from "views/admin/main/profile/newsfeed";

import ApplicationsKanban from "views/admin/main/applications/kanban";
import ApplicationsDataTables from "views/admin/main/applications/dataTables";

import EcommerceNewProduct from "views/admin/main/ecommerce/newProduct";
import EcommerceProductSettings from "views/admin/main/ecommerce/settingsProduct";
import EcommerceProductPage from "views/admin/main/ecommerce/productPage";
import EcommerceOrderList from "views/admin/main/ecommerce/orderList";
import EcommerceOrderDetails from "views/admin/main/ecommerce/orderDetails";
import EcommerceReferrals from "views/admin/main/ecommerce/referrals";

// Others
import OthersNotifications from "views/admin/main/others/notifications";
//import OthersPricing from 'views/admin/main/others/pricing';
import OthersPricing from "views/admin/main/others/pricing";
import OthersError from "views/admin/main/others/404";
import Buttons from "views/admin/main/others/buttons";
import Messages from "views/admin/main/others/messages";

// Auth Imports
import ForgotPasswordCentered from "views/auth/forgotPassword/ForgotPasswordCenter.jsx";
import ForgotPasswordDefault from "views/auth/forgotPassword/ForgotPasswordDefault.jsx";
import NewPasswordDefault from "views/auth/forgotPassword/NewPasswordDefault.jsx";
import LockCentered from "views/auth/lock/LockCenter.jsx";
import LockDefault from "views/auth/lock/LockDefault.jsx";
import SignInCentered from "views/auth/signIn/SignInCenter.jsx";
import SignInDefault from "views/auth/signIn/SignInDefault.jsx";
import SignUpCentered from "views/auth/signUp/SignUpCenter.jsx";
import SignUpDefault from "views/auth/signUp/SignUpDefault.jsx";
import VerificationCentered from "views/auth/verification/VerificationCenter.jsx";
import VerificationDefault from "views/auth/verification/VerificationDefault.jsx";
import FirstPassword from "views/auth/firstPassword/FirstPassword.jsx";

// PM IMPORTS
import ProductForm from "views/admin/pm-main/products/form";
import ListProduct from "views/admin/pm-main/products/list";
import ProductsAnalytics from "views/admin/pm-main/products/analytics";
import ProductView from "views/admin/pm-main/products/view";
import LoadProduct from "views/admin/pm-main/products/load";

import ListProductCategory from "views/admin/pm-main/products/product_category/list";
import FormProductCategory from "views/admin/pm-main/products/product_category/form";

import ListClients from "views/admin/pm-main/clients/list";
import ClientForm from "views/admin/pm-main/clients/form";

import ListSupplier from "views/admin/pm-main/supplier/list";
import SupplierForm from "views/admin/pm-main/supplier/form";

import ListOrder from "views/admin/pm-main/orders/list";
import OrderForm from "views/admin/pm-main/orders/form";
import OrderDetail from "views/admin/pm-main/orders/details";
import OrderTracking from "views/admin/pm-main/orders/tracking";

import ListEmployee from "views/admin/pm-main/employees/list";
import EmployeeForm from "views/admin/pm-main/employees/form";

import ListImporter from "views/admin/pm-main/importers/list";
import ImporterForm from "views/admin/pm-main/importers/form";

import ListShipper from "views/admin/pm-main/shippers/list";
import ShipperForm from "views/admin/pm-main/shippers/form";

// delivery
import ListContainer from "views/admin/pm-main/delivery/container/list";

const routes = [

  // --- Dashboard ---
  {
    name: `Dashboard`,
    icon: <MdDashboard className="text-inherit h-5 w-5" />,
    layout: "/admin",
    path: "/dashboard",
    collapse: false,
    exact: true,
    component: <DashboardsDefault key="main-dashboard" />,
    hidden: false,
    allowedGroups: ["Administrator"],
  },
  // --- Products ---
  {
    name: `Products`,
    icon: <MdOutlineShoppingCart className="text-inherit h-5 w-5" />,
    layout: "/admin",
    path: "/product",
    collapse: false,
    exact: true,
    component: <ListProduct key="list-product" />,
    hidden: false,
    allowedGroups: ["Client Administrator", "Client Staff"],
  },
  {
    name: "Products",
    path: "/product",
    icon: <MdOutlineShoppingCart className="text-inherit h-5 w-5" />,
    collapse: true,
    allowedGroups: ["Administrator", "Staff"],
    items: [
      {
        name: "Products",
        layout: "/admin",
        path: "/product/list",
        exact: false,
        component: <ListProduct />,
        allowedGroups: ["Administrator", "Staff"],
      },
      {
        name: "New Product",
        layout: "/admin",
        path: "/product/new",
        exact: false,
        component: <ProductForm key="new" />,
        hidden: true,
        allowedGroups: ["Administrator", "Staff"],
      },
      {
        name: "Edit Product",
        layout: "/admin",
        path: "/product/edit/:id",
        exact: false,
        component: <ProductForm key="edit" />,
        hidden: true,
        allowedGroups: ["Administrator", "Staff"],
      },
      {
        name: "Detail Product",
        layout: "/admin",
        path: "/product/detail/:id",
        exact: false,
        component: <ProductView key="detail" />,
        hidden: true,
        allowedGroups: ["Administrator", "Staff", "Client Administrator", "Client Staff"],
      },
      {
        name: "Load Products",
        layout: "/admin",
        path: "/product/load",
        exact: false,
        component: <LoadProduct />,
        allowedGroups: ["Administrator", "Staff"],
      },
      {
        name: "Categories",
        layout: "/admin",
        path: "/product/category/list",
        exact: false,
        component: <ListProductCategory />,
        allowedGroups: ["Administrator", "Staff"],
      },
      {
        name: "New Category",
        layout: "/admin",
        path: "/product/category/new",
        exact: false,
        component: <FormProductCategory key="new" />,
        hidden: true,
        allowedGroups: ["Administrator", "Staff"],
      },
      {
        name: "Edit Category",
        layout: "/admin",
        path: "/product/category/edit/:id",
        exact: false,
        component: <FormProductCategory key="edit" />,
        hidden: true,
        allowedGroups: ["Administrator", "Staff"],
      },
      {
        name: "Analytics",
        layout: "/admin",
        path: "/product/analytics",
        exact: false,
        component: <ProductsAnalytics />,
        allowedGroups: ["Administrator", "Staff"],
      },
    ],
  },
  // --- Orders ---
  {
    name: `Orders`,
    icon: <MdFactCheck className="text-inherit h-5 w-5" />,
    layout: "/admin",
    path: "/order/sales-order/tracking",
    collapse: false,
    exact: true,
    component: <OrderTracking key="list-tracking" />,
    hidden: false,
    allowedGroups: ["Administrator", "Staff", "Client Administrator", "Client Staff"],
  },
  {
    name: `Orders`,
    icon: <MdPeopleAlt className="text-inherit h-5 w-5" />,
    layout: "/admin",
    path: "/order/sales-order/list",
    collapse: false,
    exact: true,
    component: <OrderTracking key="list-order" />,
    hidden: true,
    allowedGroups: ["Administrator", "Staff"],
  },
  {
    name: `Edit Orders`,
    layout: "/admin",
    path: "/order/sales-order/edit/:id",
    exact: false,
    component: <OrderForm key="edit-order" />,
    hidden: true,
    allowedGroups: ["Administrator", "Staff", "Client Administrator", "Client Staff"],
  },
  {
    name: `New Orders`,
    layout: "/admin",
    path: "/order/sales-order/new",
    exact: false,
    component: <OrderForm key="new-order" />,
    hidden: true,
    allowedGroups: ["Administrator", "Staff", "Client Administrator", "Client Staff"],
  },
  {
    name: `New Orders`,
    layout: "/admin",
    path: "/order/sales-order/detail/:id",
    exact: false,
    component: <OrderDetail key="detail-order" />,
    hidden: true,
    allowedGroups: ["Administrator", "Staff", "Client Administrator", "Client Staff"],
  },


  // --- Delivery ---
  {
    name: `Delivery`,
    icon: <FaShip className="text-inherit h-5 w-5" />,
    layout: "/admin",
    path: "/delivery",
    collapse: false,
    exact: true,
    component: <ListContainer key="list-container" />,
    hidden: false,
    allowedGroups: ["Administrator", "Staff"],
  },

  // --- Clients ---
  {
    name: `Clients`,
    icon: <IoBusinessOutline className="text-inherit h-5 w-5" />,
    layout: "/admin",
    path: "/client/list",
    collapse: false,
    exact: true,
    component: <ListClients key="list-client" />,
    allowedGroups: ["Administrator", "Staff"],
  },
  {
    name: `Edit Client`,
    layout: "/admin",
    path: "/client/edit/:id",
    exact: false,
    component: <ClientForm key="edit-client" />,
    hidden: true,
    allowedGroups: ["Administrator", "Staff"],
  },
  {
    name: `New Client`,
    layout: "/admin",
    path: "/client/new",
    exact: false,
    component: <ClientForm key="new-client" />,
    hidden: true,
    allowedGroups: ["Administrator", "Staff"],
  },

  // --- Supplier ---
  {
    name: `Suppliers`,
    icon: <MdBusiness className="text-inherit h-5 w-5" />,
    layout: "/admin",
    path: "/supplier/list",
    collapse: false,
    exact: true,
    component: <ListSupplier key="list-supplier" />,
    allowedGroups: ["Administrator", "Staff"],
  },
  {
    name: `Edit Supplier`,
    layout: "/admin",
    path: "/supplier/edit/:id",
    exact: false,
    component: <SupplierForm key="edit-supplier" />,
    hidden: true,
    allowedGroups: ["Administrator", "Staff"],
  },
  {
    name: `New Supplier`,
    layout: "/admin",
    path: "/supplier/new",
    exact: false,
    component: <SupplierForm key="new-supplier" />,
    hidden: true,
    allowedGroups: ["Administrator", "Staff"],
  },
  // --- Importers ---
  {
    name: `Importers`,
    icon: <MdOutlineAddBusiness className="text-inherit h-5 w-5" />,
    layout: "/admin",
    path: "/client/importer/list",
    collapse: false,
    exact: true,
    component: <ListImporter key="list-importer" />,
    allowedGroups: ["Administrator", "Staff"],
  },
  {
    name: `Edit Importer`,
    layout: "/admin",
    path: "/client/importer/edit/:id",
    exact: false,
    component: <ImporterForm key="edit-importer" />,
    hidden: true,
    allowedGroups: ["Administrator", "Staff"],
  },
  {
    name: `New Importer`,
    layout: "/admin",
    path: "/client/importer/new",
    exact: false,
    component: <ImporterForm key="new-importer" />,
    hidden: true,
    allowedGroups: ["Administrator", "Staff"],
  },
  // --- Shippers ---
  {
    name: `Shippers`,
    icon: <FaPlaneDeparture className="text-inherit h-5 w-5" />,
    layout: "/admin",
    path: "/shipper/list",
    collapse: false,
    exact: true,
    component: <ListShipper />,
    allowedGroups: ["Administrator", "Staff"],
  },
  {
    name: `Shippers`,
    layout: "/admin",
    path: "/shipper/edit/:id",
    exact: false,
    component: <ShipperForm key="edit" />,
    hidden: true,
    allowedGroups: ["Administrator", "Staff"],
  },
  {
    name: `Shippers`,
    layout: "/admin",
    path: "/shipper/new",
    exact: false,
    component: <ShipperForm key="new" />,
    hidden: true,
    allowedGroups: ["Administrator", "Staff"],
  },
  // --- Employees ---
  {
    name: `Users`,
    icon: <MdPeopleAlt className="text-inherit h-5 w-5" />,
    layout: "/admin",
    path: "/users/list",
    collapse: false,
    exact: true,
    component: <ListEmployee key="list-users" />,
    allowedGroups: ["Administrator", "Staff", "Client Administrator"],
  },
  {
    name: `Edit User`,
    layout: "/admin",
    path: "/authentication/users/edit/:id",
    exact: false,
    component: <EmployeeForm key="edit-users" />,
    hidden: true,
    allowedGroups: ["Administrator", "Staff", "Client Administrator", "Client Staff"],
  },
  {
    name: `New User`,
    layout: "/admin",
    path: "/authentication/users/new",
    exact: false,
    component: <EmployeeForm key="new-users" />,
    hidden: true,
    allowedGroups: ["Administrator", "Staff", "Client Administrator"],
  },
  // // --- Authentication ---
  {
    name: "Authentication",
    path: "/auth",
    icon: <MdLock className="text-inherit h-5 w-5" />,
    collapse: true,
    hidden: true,
    items: [
      // --- Sign In ---
      {
        name: "Default",
        layout: "/auth",
        path: "/sign-in",
        component: <SignInDefault />,
      },
      // --- Forgot Password ---
      {
        name: "Default",
        layout: "/auth",
        path: "/forgot-password",
        component: <ForgotPasswordDefault />,
      },
      // --- First Password User ---
      {
        name: "First Password",
        layout: "/auth",
        path: "/first-password-user",
        component: <FirstPassword />,
      },
      // --- New Password ---
      {
        name: "Default",
        layout: "/auth",
        path: "/reset-password",
        component: <NewPasswordDefault />,
      },
      // --- Lock ---
      {
        name: "Default",
        layout: "/auth/lock",
        path: "/lock/default",
        component: <LockDefault />,
      },
    ],
  },
];
export default routes;
